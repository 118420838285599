<template>
  <b-container>
    <b-overlay
      :show="isBusy"
      rounded="sm"
      spinner-type="grow"
      spinner-variant="primary"
    >
      <FilterComponent
        :store="store" 
        :filter="filter"
        @filter-now="filter = '';changePage(1);" 
        @change-page="changePage($event)"
        @add-new="add()"
        @filter-word="filter = $event"
      >
        <template v-slot:header-link>
          <b-button
            to="/tariff-types"
            size="sm mr-1"
            variant="info"
          >
            Tariff Types
          </b-button>
        </template>
      </FilterComponent>
      <b-alert
        show
        variant="danger"
        dismissible
      >
        <strong>Note:</strong> Also available at <a href="https://ssiplengg.com">https://ssiplengg.com</a>.
      </b-alert>

      <b-alert
        show
        variant="info"
        dismissible
      >
        <strong>Note:</strong> Deleting a category deletes all associated consumers. 
        Please consider editing the same.
      </b-alert>

      <CrudComponent
        :store.sync="store"
        :filter="filter"
        :fetch="fetchItems"
        :formerr.sync="formError"
        @editNow="edit"
        @showNow="show"
        @delNow="del"
        @changeNow="changePage"
      />
    </b-overlay>

    <!-- Edit modal -->
    <b-modal
      id="modal"
      centered
      :title="form.formTitle"
      hide-footer
    >
      <b-alert 
        v-if="alert.message" 
        :show="5" 
        dismissible 
        :variant="alert.type" 
        @dismissed="clearAlert"
      >
        {{ alert.message }}
      </b-alert>

      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group
          id="input-group-1"
          label="Name"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="$v.form.item.name.$model"
            name="name"
            :state="validateState('name')"
            aria-describedby="name-live-feedback"
          />

          <b-form-invalid-feedback
            id="name-live-feedback"
          >
            This is a required field and must be at least 3 characters.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Slug"
          label-for="slug"
        >
          <b-form-input
            id="slug"
            v-model="$v.form.item.slug.$model"
            name="slug"
            :state="validateState('slug')"
            aria-describedby="slug-live-feedback"
          />

          <b-form-invalid-feedback id="slug-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="MFC"
          label-for="mfc"
        >
          <b-form-input
            id="mfc"
            v-model="$v.form.item.mfc.$model"
            name="mfc"
            :state="validateState('mfc')"
            aria-describedby="mfc-live-feedback"
          />
        </b-form-group>

        <div class="text-center">
          <b-button
            type="submit"
            variant="primary"
          >
            Submit
          </b-button>
        </div>
      </b-form>
    </b-modal>

    
    <!-- Show modal -->
    <b-modal
      id="show-modal"
      centered
      title="Show Item"
      ok-only
      size="md"
    >
      <ul class="list-group">
        <li
          v-for="(val, index) in form.item"
          :key="index"
          class="list-group-item"
        >
          {{ index + ' : ' + val }}
        </li>
      </ul>
    </b-modal>
  </b-container>
</template>

<style scoped>

</style>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import FilterComponent from '@/components/FilterComponent';
import CrudComponent from '@/components/CrudComponent';

export default {
  name: 'MaterTypes',
  components:{
    FilterComponent,
    CrudComponent
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        formTitle: 'Create Item',
        mode: 'add',
        item: {
          id: null,
          name: null,
          slug: null,
          mfc: null
        }
      },
      isBusy: false,
      filter: '',
    }
  },
  validations: {
    form: {
      item: {
        name: {
          required,
          minLength: minLength(3)
        },
        slug: {
          required
        },
        mfc: {
          required
        }
        
      }
    }
  },
  computed: {
    ...mapState({ alert: state => state.alert }),
    ...mapState("metertype", ['formError']),
    ...mapGetters("metertype", ['store'])
  },
  watch: {
    formError(newVal){
      if(!newVal){
        this.$bvModal.hide('modal');
      }
    }
  },
  methods: {
    ...mapActions("metertype", [
      "fetchItems", 
      "addItem", 
      "deleteItem", 
      "updateItem", 
      "changePage"
    ]),
    ...mapActions({ clearAlert: 'alert/clear' }),
    
    // 1. Prepare modal for creation of item.
    add() {
      this.$v.$reset();
      this.form.formTitle = 'Create Item:';
      this.form.mode = 'add';
      this.form.item = {
        id: null,
        name: null,
        slug: null,
        rent: null
      };
      this.$root.$emit('bv::show::modal', 'modal')
    },

    // Prepare model for updation.
    edit(item, button) {
      this.form = {
        formTitle: 'Edit Item:',
        mode: 'update',
        item: JSON.parse(JSON.stringify(item))
      };
      this.$root.$emit('bv::show::modal', 'modal', button)
    },

    // 2. Validation after creating form.
    validateState(name) {
      const { $dirty, $error } = this.$v.form.item[name];
      return $dirty ? !$error : null;
    },

    // 3. Handle Submit - Call Store fundtion.
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if(this.form.mode == 'update'){
        this.updateItem(this.form.item);
      }else{
        this.addItem(this.form.item);
      }
    },

    // 4. Show Item details in another Modal.
    show(item, index, button) {
      this.form.item = item;
      this.$root.$emit('bv::show::modal', 'show-modal', button)
    },

    // 5. Delete the item.
    del(id) {
      let res = window.confirm("Are you sure?");
      if(res) this.deleteItem(id);
    },
  }
}
</script>